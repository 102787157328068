import React from 'react';
import { Link } from 'gatsby';
import { getExcerpt } from '../utils/htmlParse';
import Image from '../components/Image';
import './RelatedProjects.scss';

export const RelatedProjects = (props) => {
  const {
    title,
    showcaseSlug,
    relatedProjects
  } = props;
  if (!relatedProjects) return null;
  return (
    <section className="related-projects">
      <div className="wrapper">
        <h2 dangerouslySetInnerHTML={{__html: title}} />
        <div className="projects">
          {relatedProjects && relatedProjects.edges.map(({node: project}) => {
            const { title: projectTitle, featuredImage, content, slug: projectSlug } = project;
            const projectExcerpt = getExcerpt(content, 160);
            return (
              <div className="project" key={projectSlug}>
                <div className="related-project-body">
                  <h3 dangerouslySetInnerHTML={{__html: projectTitle}} />
                  <div className="related-project-excerpt" dangerouslySetInnerHTML={{__html: projectExcerpt}} />
                  <Link className="styled-button" to={`/${showcaseSlug}/${projectSlug}/`}>Learn more</Link>
                </div>
                <Image className="related-project-image" image={featuredImage} />
              </div>
            );
          })}
        </div>
        <Link className="styled-button view-more" to={`/${showcaseSlug}/`}>View all projects</Link>
      </div>
    </section>
  );
}
